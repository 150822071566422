<template>

  <section id="seguridad-categorias">

    <div class="row mt-3">
      <div class="col-12">
        <h3 class="section-head-title ps-0 mb-3">
          <font-awesome-icon 
            icon="border-all" 
            class="color-secondary pe-1"
          />
          Revisa o crea categorías para tus videos.
        </h3>
  
        <div class="card card-custom border-round-10 card-shadow border-0 mb-3">
          <div class="card-body d-flex flex-column mh-700">
            <div class="row d-flex align-items-center my-3">
              <div class="col-12 col-lg-6">
                <h5 class="font-main text-secondary">
                  Lista de categorias creadas
                </h5>
              </div>
              <div class="col-12 col-lg-6">
                <div class="input-group">
                  <input 
                    type="text" 
                    class="form-control input-custom" 
                    maxlength="30"
                    v-model="new_category_name"
                    @keypress.enter="addNewCat()"
                    @click="edit_category = null"
                    placeholder="Escribe un nombre para crear una nueva categoría"
                  >
                  <button 
                    @click="addNewCat()"
                    class="btn btn-custom-color-white border" 
                    type="button">
                    <font-awesome-icon 
                      icon="plus" 
                      class="color-secondary"
                    />
                  </button>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <ul 
                  v-if="categories_list.length > 0"
                  class="list-group">
                  <li 
                    class="list-group-item d-flex justify-content-between align-items-center"
                    v-for="(cat, k) in categories_list"
                    :key="k"
                  >
                    <span 
                      v-if="edit_category !== cat.id_categoria"
                      class="text-secondary"
                    >
                      <font-awesome-icon 
                        icon="border-all" 
                        class="pe-1"
                      />
                      {{ cat.nombre }}
                    </span>

                    <span 
                      v-if="edit_category == cat.id_categoria"
                      class="col-6"
                    >
                      <input 
                        type="text" 
                        v-model="edit_category_name" 
                        maxlength="30"
                        class="form-control input-custom"
                        @keypress.enter="editCategory(cat)"
                      />
                    </span>

                    <div 
                      v-if="edit_category !== cat.id_categoria"
                      class="dropdown dropdown-custom d-flex justify-content-center">
                      <button 
                        class="dropdown-toggle dropdown-custom-action dropdown-custom-arrow-hide" 
                        type="button" 
                        data-bs-toggle="dropdown">
                        <font-awesome-icon icon="ellipsis-vertical"/>
                      </button>
                      <ul class="dropdown-menu">
                        <li>
                          <a 
                            @click="activeEditCat(cat)"
                            class="dropdown-item" 
                            href="javascript:">
                            <font-awesome-icon class="pe-2 color-brown" icon="pen"/>
                            Editar
                          </a>
                        </li>
                        <li>
                          <a 
                            @click="beforeDeleteCategory(cat)"
                            class="dropdown-item" 
                            href="javascript:">
                            <font-awesome-icon class="pe-2 color-red" icon="trash-alt"/>
                            Eliminar
                          </a>
                        </li>
                      </ul>
                    </div>

                    <div v-if="edit_category == cat.id_categoria">
                      <a
                        href="javascript:"
                        class="fs-5 me-2 btn btn-custom-color-white border"
                        @click="edit_category = null"
                      >
                        <font-awesome-icon icon="circle-xmark" class="color-red"/>
                      </a>
                      <a
                        href="javascript:"
                        class="fs-5 btn btn-custom-color-white border"
                        @click="editCategory(cat)"
                      >
                      <font-awesome-icon icon="circle-check" class="color-green"/>
                    </a>
                    </div>

                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> 

    <Status
      v-if="open_modal_status"
      :msg="modal_status_msg"
      :status="modal_status"
      @close="closeStatus"
    />
    <Question
      v-if="open_question_modal"
      :msg="question_modal_msg"
      :hideCancel="false"
      @cancel="cancelQuestion"
      @accept="acceptQuestion"
    />
    <Spinner v-if="show_spinner" />
  </section>

</template>

<script>
import Status from "../Modales/Status.vue";
import Question from "../Modales/Question.vue";
import {
  ADD_SAFETY_CATEGORY_DOC,
  DELETE_SAFETY_CATEGORY_DOC,
  EDIT_SAFETY_CATEGORY_DOC,
} from "@/apollo/mutations";
import { MODULOS } from "@/apollo/queries";
import Spinner from "../Spinner.vue";
import { mapActions, mapState } from "vuex";

export default {
  components: { Status, Question, Spinner },
  data() {
    return {
      categories_list: [],
      new_category_name: "",
      edit_category_name: "",
      open_modal_status: false,
      modal_status_msg: "",
      modal_status: true,
      open_question_modal: false,
      question_modal_msg: "",
      edit_category: null,
      safety_module_id: "",
      id_empresa: this.$ls.get("user").empresa[0].id_empresa,
      show_spinner: false,
      cat_to_delete: null,
      repeated_cat: undefined,
    };
  },
  mounted() {
    this.getModulos();
  },
  computed: {
    ...mapState("safetyModule", ["safety_cat_list"]),
  },
  methods: {
    ...mapActions("safetyModule", [
      "getSafeCatsList",
      "deleteSafeCatAction",
      "addSafeCatAction",
      "editSafeCatAction",
    ]),
    // Agregar categoria
    addNewCat() {
      if (this.new_category_name !== "") {
        this.show_spinner = true;

        this.$apollo
          .mutate({
            mutation: ADD_SAFETY_CATEGORY_DOC,
            variables: {
              nombre: this.new_category_name,
              archivo: "",
              formato_archivo: "",
              id_empresa: this.id_empresa,
              id_modulo: this.safety_module_id,
              id_creador: this.$ls.get("user").id_usuario,
            },
          })
          .then((res) => {
            var resp = res.data.agregarCategoriaDocumento;
            this.show_spinner = false;
            if (resp.id_categoria) {
              this.addSafeCatAction(resp);
              this.$toast.open({
              message:'Categoría creada correctamente.',
              type:'success',
              duration:6000,
              position:'top-right'
            });
            }
            this.new_category_name = "";
          })
          .catch((err) => {
            console.log("err", err);
            this.$toast.open({
              message:'Ocurrió un error al crear la categoría, intentalo nuevamente.',
              type:'error',
              duration:0,
              position:'top-right'
            });
          });
      }
    },
    // pregunta antes de eliminar
    beforeDeleteCategory(cat) {
      this.cat_to_delete = cat;
      this.open_question_modal = true;
      this.question_modal_msg =
        "¿Realmente quieres eliminar esta categoría?  &nbsp;";
    },
    cancelQuestion() {
      this.open_question_modal = false;
    },
    // Eliminar categoria
    acceptQuestion() {
      this.show_spinner = true;
      this.$apollo
        .mutate({
          mutation: DELETE_SAFETY_CATEGORY_DOC,
          variables: {
            id_categoria: this.cat_to_delete.id_categoria,
            id_empresa: this.id_empresa,
            id_eliminador: this.$ls.get("user").id_usuario,
            id_modulo: this.safety_module_id,
          },
        })
        .then((res) => {
          var resp = res.data.eliminarCategoriaDocumento;
          this.show_spinner = false;
          if (resp.id_categoria) {
            this.deleteSafeCatAction(resp.id_categoria);
            setTimeout(() => {
              this.categories_list = this.safety_cat_list;
            }, 100);
            if (resp.id_categoria != "0") {
              this.open_question_modal = false;
              this.$toast.open({
                message:'Categoría eliminada correctamente.',
                type:'success',
                duration:6000,
                position:'top-right'
              });
            } else {
              this.open_question_modal = false;
              this.$toast.open({
                message:'La categoría no pudo ser eliminada, ya que tiene documentos asociados.',
                type:'error',
                duration:0,
                position:'top-right'
              });
            }
          }
        })
        .catch((err) => {
          console.log("error eliminar categoria:", err);
          this.$toast.open({
                message:'Ocurrió un error al eliminar la categoría, intentalo nuevamente.',
                type:'error',
                duration:0,
                position:'top-right'
              });
          this.show_spinner = false;
        });
    },
    // activa opciones de editar y muestra input con nombre de categoria
    activeEditCat(cat) {
      this.edit_category = cat.id_categoria;
      this.edit_category_name = cat.nombre;
    },
    // Editar categoria
    editCategory(category) {
      this.repeated_cat = this.categories_list.find(
        (cat) => cat.nombre == this.edit_category_name
      );

      if (this.edit_category_name !== category.nombre) {
        if (this.repeated_cat == undefined) {
          this.$apollo
            .mutate({
              mutation: EDIT_SAFETY_CATEGORY_DOC,
              variables: {
                nombre: this.edit_category_name,
                archivo: "",
                formato_archivo: "",
                id_empresa: this.id_empresa,
                id_modulo: this.safety_module_id,
                id_modificador: this.$ls.get("user").id_usuario,
                id_categoria: category.id_categoria,
              },
            })
            .then((res) => {
              var resp = res.data.editarCategoriaDocumento;
              this.show_spinner = false;
              if (resp.id_categoria) {
                this.editSafeCatAction(resp);
                this.$toast.open({
                  message:'Categoría editada correctamente.',
                  type:'success',
                  duration:6000,
                  position:'top-right'
                });
              }
            })
            .catch((err) => {
              console.log("err", err);
              this.$toast.open({
                message:'Ocurrió un error al editar la categoria.',
                type:'error',
                duration:0,
                position:'top-right'
              });
            })
            .finally(() => {
              this.edit_category = null;
            });
        } else {
          this.$toast.open({
                message:'Ocurrió un error al editar la categoría, el nombre ya existe.',
                type:'error',
                duration:0,
                position:'top-right'
              });
          this.repeated_cat = category;
        }
      }
    },
    getModulos() {
      this.show_spinner = true;
      this.$apollo
        .query({
          query: MODULOS,
        })
        .then((res) => {
          var resp = res.data.modulos;
          resp.forEach((element) => {
            if (element.nombre_modulo == "Seguridad") {
              this.safety_module_id = element.id_modulo;

              var variables_graph = {
                id_empresa: this.id_empresa,
                id_modulo: element.id_modulo,
              };
              this.getSafeCatsList(variables_graph); //llamada a vuex

              setTimeout(() => {
                this.show_spinner = false;
                this.categories_list = this.safety_cat_list;
              }, 2000);
            }
          });
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    closeStatus() {
      this.open_modal_status = false;
      this.edit_category = null;
      if (this.repeated_cat !== undefined) {
        this.activeEditCat(this.repeated_cat);
      }
    },
  },
};
</script>
